import React from "react"
import { ToastContainer, toast } from "react-toastify"

export default function Publish() {
  const PUBLISH_WEBHOOK =
    "https://webhooks.amplify.ap-south-1.amazonaws.com/prod/webhooks?id=f05cc66c-a4d0-4c34-9cc9-90054c19b5d6&token=JhbU07XTtG9f8UPUt48dvirDZy1iNBIzwvVDjBZ4"
  const handleTrigger = async () => {
    await fetch(PUBLISH_WEBHOOK, {
      method: "POST",
    })
      .then(response => {
        if (response.ok) {
          console.log("Request successful")
          return toast.success("Success", {
            position: toast.POSITION.TOP_CENTER,
          })
        } else {
          console.error("Request failed")
          return toast.error("Request failed", {
            position: toast.POSITION.TOP_CENTER,
          })
        }
      })
      .catch(error => {
        console.error("Error:", error)
        return toast.error(error, {
          position: toast.POSITION.TOP_CENTER,
        })
      })
  }
  return (
    <>
      <ToastContainer />

      <button type="button" onClick={handleTrigger}>
        Release Changes
      </button>
    </>
  )
}
